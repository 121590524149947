<template>
	<div class="outer-wrapper filters"> 

		<div id="top-nav"> 
			<router-link :to="{ name: 'Tours Filters' }">
				<img src="@/assets/back-icon.png" alt="back" class="back-icon"/>
			</router-link>
		</div>
	
		<div class="inner-wrapper main"> <!-- Inner wrapper handles global padding -->

			<div class="heading-section">
				<img class="icon" width="19px" src="@/assets/filters/price.png" />
				<h1 class="heading6">Price</h1>
			</div>
			
			<RangeSlider 
				v-if="searchFilters.priceRange"
				
				class="slider"
				:minValue="filterOptions.priceRange.low"
				:maxValue="filterOptions.priceRange.high"
				:incrementValue="15"
				:formatFunction="formatPrice"
				v-model="selectedPriceRange"

				@change="update()"
			/>

		</div>
		
			
		<div class="bottom inner-wrapper">
			<router-link :to="{ name: 'Tours Filters' }">
				<button class="button">Continue</button>
			</router-link>
		</div>
	</div>
</template>


<script>
import { mapState, mapGetters } from 'vuex';
import helpers from '@/helpers/helpers.js';
import RangeSlider from '@/components/RangeSlider.vue';
import router from '@/router';

export default {
    data () {
        return {
			selectedPriceRange: { low: false, high: false }
        }
	},
	components: {
		RangeSlider,
	},
   computed: {
		...mapState({
			searchFilters: state => state.toursSearch.searchFilters,
			searchResults: state => state.toursSearch.searchResults,
			searchValues: state => state.toursSearch.searchValues,
		}),
		...mapGetters({
			filterOptions: 'toursSearch/getFilterOptions',
		})
	},
    methods: {
		formatPrice: (price) => helpers.formatPrice("GBP", price),
		update() { // If the selected price range == max price ranges, then turn filter off
			this.searchFilters.priceRange.low = this.selectedPriceRange.low;
			this.searchFilters.priceRange.high = this.selectedPriceRange.high;
			if(this.filterOptions.priceRange.low == this.selectedPriceRange.low && this.filterOptions.priceRange.high == this.selectedPriceRange.high) {
				this.searchFilters.priceRange.low = false;
				this.searchFilters.priceRange.high = false;
			}
		}
	},
	created () {
		if(this.searchResults.length == 0) { // Probably a page refresh - redirect to results page (with query params)
			router.push({
				name: 'Tours Results',
			})
		}
		this.selectedPriceRange = this.searchFilters.priceRange;
	},
	
};
</script>


<style scoped>
	.heading-section {
		text-align: left;
	}

	.slider {
		margin-top: 20px;
	}

	.return {
		margin-top: 50px;
	}
	
</style>